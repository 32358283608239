


export const CREATE_COURSE = 'CREATE_COURSE'
export const CREATE_COURSE_CHAPTER = 'CREATE_COURSE_CHAPTER'
export const CREATE_CHAPTER_VIDEO = 'CREATE_CHAPTER_VIDEO'
export const GET_MY_COURSES = 'GET_MY_COURSES'
export const GET_ALL_COURSES = 'GET_ALL_COURSES'
export const DELETE_COURSE = 'DELETE_COURSE'
export const DELETE_COURSE_CHAPTER_VIDEO = 'DELETE_COURSE_CHAPTER_VIDEO'


export const GET_CART_ITEMS = 'GET_CART_ITEMS'
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'